import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=1334f302&scoped=true&"
import script from "./Detail.vue?vue&type=script&lang=js&"
export * from "./Detail.vue?vue&type=script&lang=js&"
import style0 from "./Detail.vue?vue&type=style&index=0&id=1334f302&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1334f302",
  null
  
)

/* custom blocks */
import block0 from "./Detail.vue?vue&type=custom&index=0&blockType=font-awesome-icon&%3Aicon=%5B'fas'%2C%20'square'%5D"
if (typeof block0 === 'function') block0(component)
import block1 from "./Detail.vue?vue&type=custom&index=1&blockType=font-awesome-icon&%3Aicon=%5B'far'%2C%20'square'%5D"
if (typeof block1 === 'function') block1(component)
import block2 from "./Detail.vue?vue&type=custom&index=2&blockType=font-awesome-icon&%3Aicon=%5B'fab'%2C%20'accessible-icon'%5D"
if (typeof block2 === 'function') block2(component)

export default component.exports